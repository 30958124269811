import React, {SyntheticEvent, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import axiosClient from "../../../services/axios";
import router from "../../../router";
import FormEmployer from "../../../components/Employer/Form";
import isFilled from "../../../services/checkRequired";

export interface IForm {
    name?: string,
    email?: string,
    cpf?: string,
    matricula?: string,
    cargo?: string,
    funcao?: string,
    contact?: IContact,
    rg?: string,
    address?: IAdress
}

interface IAdress {
    cep?: string,
    tipo_logradouro?: string,
    logradouro?: string,
    bairro?: string,
    cidade?: string,
    estado?: string,
    numero?: number
}

interface IContact {
    fixed_phone?: string,
    cell_phone?: string
}

const AddEmpresaComponent = () => {
    const [form, setForm] = useState<IForm>({
        name: "",
        email: "",
        cpf: "",
        matricula: "",
        cargo: "",
        funcao: "",
        contact: {
            fixed_phone: "",
            cell_phone: "",
        },
        rg: "",
        address: {
            cep: "",
            tipo_logradouro: "",
            logradouro: "",
            bairro: "",
            cidade: "",
            estado: "",
            numero: 0
        }
    });
    const [loading, setLoading] = useState(false);


    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if(isFilled()){
            axiosClient.post('/funcionarios/store', {...form, role: 4})
                .then(({data: {message}}) => {
                    toast.success(message);
                    setTimeout(function (){
                        return router?.navigate(`/funcionarios/listar`);
                    }, 800)
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
            });
        }else{
            toast.error("Por favor preencher os campos destacados.");
            setLoading(false);
        }
    }

    return <FormEmployer form={form} setForm={setForm} onSubmit={onSubmit} loading={loading} btnText={"Gerar Funcionário"}
    />;
};
export default AddEmpresaComponent;
