import {createBrowserRouter, Navigate} from "react-router-dom";
import AuthLayout from "./views/auth/layout";
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";
import DashboardLayout from "./views/dashboard/layout";
import Home from "./views/dashboard/Home";
import Vistorias from "./views/dashboard/vistorias/Vistorias";
import Vistoriadores from "./views/dashboard/vistoriadores/Vistoriadores";
import Empresas from "./views/dashboard/empresas/Empresas";
import Funcionarios from "./views/dashboard/funcionarios/Funcionarios";
import Certs from "./views/dashboard/configuracao/certs/Certs";
import AddCertComponent from "./views/dashboard/configuracao/certs/Add";
import IndexCertComponent from "./views/dashboard/configuracao/certs/Index";
import UserInfo from "./views/dashboard/configuracao/userinfo/UserInfo";
import Formularios from "./views/dashboard/formularios/Formularios";
import AddFormComponent from "./views/dashboard/formularios/Add";
import React from "react";
import IndexFormsComponent from "./views/dashboard/formularios/Index";
import IndexEmpresasComponent from "./views/dashboard/empresas/Index";
import IndexVistoriadorComponent from "./views/dashboard/vistoriadores/Index";
import IndexVistoriaComponent from "./views/dashboard/vistorias/Index";
import AddEmpresaComponent from "./views/dashboard/empresas/Add";
import AddVistoriaComponent from "./views/dashboard/vistorias/Add";
import EditFormComponent from "./views/dashboard/formularios/Edit";
import EditEmpresaComponent from "./views/dashboard/empresas/Edit";
import AddVistoriadorComponent from "./views/dashboard/vistoriadores/Add";
import EditVistoriadorComponent from "./views/dashboard/vistoriadores/Edit";
import EditVistoriaComponent from "./views/dashboard/vistorias/Edit";
import Resposta from "./views/dashboard/resposta/Resposta";
import IndexRespostaComponent from "./views/dashboard/resposta/Index";
import Campos from "./views/dashboard/campos/Campos";
import IndexCampoComponent from "./views/dashboard/campos/Index";
import AddCampoComponent from "./views/dashboard/campos/Add";
import EditCampoComponent from "./views/dashboard/campos/Edit";
import Senha from "./views/dashboard/configuracao/senha/Senha";
import {AlterPasswordComponent} from "./views/dashboard/configuracao/senha/Alter";
import {AlterUserInfoComponent} from "./views/dashboard/configuracao/userinfo/Alter";
import NotFound from "./views/NotFound";
import IndexFuncionariosComponent from "./views/dashboard/funcionarios/Index";
import AddFuncionariosComponent from "./views/dashboard/funcionarios/Add";
import EditarFuncionariosComponent from "./views/dashboard/funcionarios/Edit";

const router = createBrowserRouter([
    {
        path: '*',
        element: <Navigate to={'/404'}/>,
    },
    {
        path: '/404',
        element: <NotFound/>
    },
    {

        path: '/',
        element: <DashboardLayout/>,
        children: [
            {
                path: '/',
                element: <Navigate to={'/dashboard'}/>,
            },
            {
                path: 'dashboard',
                element: <Home/>
            },
            {
                path: 'empresas',
                element: <Empresas />,
                children: [
                    {
                        path: '/empresas',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexEmpresasComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddEmpresaComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditEmpresaComponent />
                    }
                ]
            },
            {
                path: 'funcionarios',
                element: <Funcionarios />,
                children: [
                    {
                        path: '/funcionarios',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexFuncionariosComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddFuncionariosComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditarFuncionariosComponent />
                    }
                ]
            },
            {
                path: 'vistoriadores',
                element: <Vistoriadores />,
                children: [
                    {
                        path: '/vistoriadores',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexVistoriadorComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddVistoriadorComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditVistoriadorComponent />
                    }
                ]
            },
            {
                path: 'vistorias',
                element: <Vistorias />,
                children: [
                    {
                        path: '/vistorias',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexVistoriaComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddVistoriaComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditVistoriaComponent />
                    }
                ]
            },
            {
                path: 'formularios',
                element: <Formularios />,
                children: [
                    {
                        path: '/formularios',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexFormsComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddFormComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditFormComponent />
                    }
                ]
            },
            {
                path: 'campos',
                element: <Campos />,
                children: [
                    {
                        path: '/campos',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'listar',
                        element: <IndexCampoComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddCampoComponent />
                    },
                    {
                        path: 'editar/:id',
                        element: <EditCampoComponent />
                    }
                ]
            },
            {
                path: 'resposta',
                element: <Resposta />,
                children: [
                    {
                        path: '/resposta',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: '/resposta/:id',
                        element: <IndexRespostaComponent />
                    }
                ]
            },
            {
                path: 'certificado',
                element: <Certs/>,
                children: [
                    {
                        path: '/certificado',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                      path: 'listar',
                        element: <IndexCertComponent/>
                    },
                    {
                        path: 'adicionar',
                        element: <AddCertComponent />
                    },
                ]
            },
            {
                path: 'meu-usuario',
                element: <UserInfo/>,
                children: [
                    {
                        path: '/meu-usuario',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'alterar',
                        element: <AlterUserInfoComponent />
                    },
                ]
            },
            {
                path: 'senha',
                element: <Senha/>,
                children: [
                    {
                        path: '/senha',
                        element: <Navigate to={'/dashboard'}/>,
                    },
                    {
                        path: 'alterar',
                        element: <AlterPasswordComponent/>
                    }
                ]
            }
        ]
    },
    {
        path: '/convidado',
        element: <AuthLayout />,
        children:[
            {
                path: '/convidado',
                element: <Navigate to={'/convidado/login'} />,
            },
            {
                path: 'login',
                element: <Login/>
            },
            {
                path: 'recuperar-senha',
                element: <ForgetPassword/>
            },
        ]
    },
    {
        path: '/login',
        element: <Navigate to={'/convidado/login'}/>
    },
    {
        path: '/home',
        element: <Navigate to={'/'}/>
    }
]);

export default router;
