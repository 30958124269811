const isFilled = (className:string = '.isRequired') => {
    const elements = Array.from(document.querySelectorAll(className));
    console.log(elements);
    
    let isValid = true;
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLInputElement;
        if (!element.value) {
            isValid = false;
            element.style.border = '1px solid red';
            element.classList.add('border-red-500');
            element.addEventListener('input', () => {
                element.removeAttribute('style');
            });
        }
    }
    return isValid;
};

export default isFilled;