import React, { SyntheticEvent, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import axiosClient from "../../../services/axios";
import { GenerateMask } from "../Home";
import FormSurveyor from "../../../components/Surveyor/Form";
import router from "../../../router";
import IMask from "imask";
import isFilled from "../../../services/checkRequired";

export interface IForm {
    name?: string,
    email?: string,
    cpf?: string,
    mte?: string,
    fixed_phone?: string,
    cell_phone?: string,
    rg?: string,
    certifications?: []
}

const AddVistoriadorComponent = () => {
    const [form, setForm] = useState<IForm>({
        name: "",
        email: "",
        cpf: "",
        mte: "",
        fixed_phone: "",
        cell_phone: "",
        rg: "",
        certifications: []
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00")
    }, []);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if(isFilled()){
            axiosClient.post('/vistoriadores/store', { ...form, role: 3 })
                .then(({ data: { message } }) => {
                    toast.success(message);
                    setTimeout(function () {
                        return router?.navigate(`/vistoriadores/listar`);
                    }, 800)
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                });
        } else {
            toast.error("Por favor preencher os campos destacados.");
            setLoading(false);
        }
    }

    return <FormSurveyor form={form} onSubmit={onSubmit} setForm={setForm}
        loading={loading} btnText={"Gerar Vistoriador"} />;
};

export default AddVistoriadorComponent;
