import React, { FC } from "react";
import HeaderButton from "../HeaderButton";
import { HiOutlineArrowCircleLeft } from "react-icons/hi";
import Button from "../Forms/Button";
import { IForm } from "../../views/dashboard/funcionarios/Add";
import FloatingInputComponent from "../Forms/FloatingInput";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import ScrollYBoxFormsComponent from "../ScrollYBoxForms";
import axios from "axios";
import IMask from "imask";
import { toast } from "react-toastify";

interface Props {
    form: IForm;
    setForm: any;
    onSubmit: any;
    loading: any;
    btnText: string;
}

const FormEmployer: FC<Props> = ({
    form,
    setForm,
    loading,
    btnText,
    onSubmit,
}) => {
    const setName = (value: string) => setForm({ ...form, name: value });
    const setEmail = (value: string) => setForm({ ...form, email: value });
    const setCpf = (value: string) => {
        const masked = IMask.createMask({ mask: "000.000.000-00" });
        value = masked.resolve(value);
        setForm({ ...form, cpf: value });
    };
    const setMatricula = (value: string) =>
        setForm({ ...form, matricula: value });
    const setCargo = (value: string) => setForm({ ...form, cargo: value });
    const setFuncao = (value: string) => setForm({ ...form, funcao: value });
    const setPhone = (phone: string) => {
        const masked = IMask.createMask({ mask: "(00) 0000-0000" });
        phone = masked.resolve(phone);
        setForm({ ...form, contact: { ...form.contact, fixed_phone: phone} });
    };
    const setCellPhone = (phone: string) => {
        const masked = IMask.createMask({ mask: "(00) 0 0000-0000" });
        phone = masked.resolve(phone);
        setForm({ ...form, contact: { ...form.contact, cell_phone: phone } });
    };
    const setRg = (value: string) => setForm({ ...form, rg: value });
    const setCep = (cep: string) => {
        if (cep.length === 9) {
            axios
                .get(
                    "https://viacep.com.br/ws/" +
                        cep.replace(/[^0-9]/, "") +
                        "/json/"
                )
                .then(({ data }: any) => {
                    setForm({
                        ...form,
                        address: {
                            ...form.address,
                            cep: data.cep,
                            logradouro: data.logradouro,
                            bairro: data.bairro,
                            cidade: data.localidade,
                            estado: data.uf,
                        },
                    });
                })
                .catch(() => {
                    toast.error("CEP inválido.");
                });
        }
        const masked = IMask.createMask({ mask: "00000-000" });
        cep = masked.resolve(cep);
        setForm({ ...form, address: { ...form.address, cep: cep } });
    };
    const setLogradouro = (logradouro: string) =>
        setForm({ ...form, address: { ...form.address, logradouro } });
    const setBairro = (bairro: string) =>
        setForm({ ...form, address: { ...form.address, bairro } });
    const setCidade = (cidade: string) =>
        setForm({ ...form, address: { ...form.address, cidade } });
    const setEstado = (estado: string) =>
        setForm({ ...form, address: { ...form.address, estado } });
    const setNumero = (numero: number) =>
        setForm({ ...form, address: { ...form.address, numero } });

    return (
        <>
            <header
                className={"max-w-sm sm:max-w-2xl flex justify-center gap-4"}
            >
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={"flex"}>
                        <HeaderButton url={"funcionarios/listar"} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft
                                className={"text-6xl text-main-color"}
                            />
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main
                    className={
                        "max-w-sm sm:max-w-2xl flex items-center justify-center"
                    }
                >
                    <form
                        action={"#"}
                        method={"POST"}
                        onSubmit={onSubmit}
                        className={
                            "flex flex-col gap-4 w-full shadown rounded-xl"
                        }
                    >
                        <>
                            <FloatingInputComponent
                                type={"text"}
                                label={"Nome Completo*"}
                                name={"name"}
                                id={"name"}
                                classes={['isRequired']}
                                value={form.name}
                                onchange={setName}
                            />
                            <FloatingInputComponent
                                type={"email"}
                                label={"E-mail*"}
                                name={"email"}
                                classes={['isRequired']}
                                id={"email"}
                                value={form.email}
                                onchange={setEmail}
                            />
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"CPF*"}
                                    name={"cpf"}
                                    classes={['isRequired']}
                                    id={"cpf"}
                                    value={form.cpf}
                                    onchange={setCpf}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"RG"}
                                    name={"rg"}
                                    id={"rg"}
                                    value={form.rg}
                                    onchange={setRg}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Matricula"}
                                    name={"matricula"}
                                    id={"matricula"}
                                    value={form?.matricula}
                                    onchange={setMatricula}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Cargo"}
                                    name={"cargo"}
                                    id={"cargo"}
                                    value={form.cargo}
                                    onchange={setCargo}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Função"}
                                    name={"funcao"}
                                    id={"funcao"}
                                    value={form.funcao}
                                    onchange={setFuncao}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Telefone"}
                                    name={"phone"}
                                    id={"phone"}
                                    value={form.contact.fixed_phone}
                                    onchange={setPhone}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Celular*"}
                                    name={"cell_phone"}
                                    classes={['isRequired']}
                                    id={"cellPhone"}
                                    value={form.contact.cell_phone}
                                    onchange={setCellPhone}
                                />
                            </div>
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"CEP*"}
                                    name={"cep"}
                                    classes={['isRequired']}
                                    id={"cep"}
                                    value={form?.address?.cep}
                                    onchange={setCep}
                                />
                            </div>
                            <FloatingInputComponent
                                type={"text"}
                                label={"Logradouro*"}
                                name={"logradouro"}
                                classes={['isRequired']}
                                id={"logradouro"}
                                value={form?.address?.logradouro}
                                onchange={setLogradouro}
                            />
                            <FloatingInputComponent
                                type={"text"}
                                label={"Bairro*"}
                                name={"bairro"}
                                classes={['isRequired']}
                                id={"bairro"}
                                value={form?.address?.bairro}
                                onchange={setBairro}
                            />
                            <FloatingInputComponent
                                type={"text"}
                                label={"Cidade*"}
                                name={"cidade"}
                                classes={['isRequired']}
                                id={"cidade"}
                                value={form?.address?.cidade}
                                onchange={setCidade}
                            />
                            <div className={"flex gap-3"}>
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Estado*"}
                                    name={"estado"}
                                    classes={['isRequired']}
                                    id={"estado"}
                                    value={form?.address?.estado}
                                    onchange={setEstado}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Numero*"}
                                    name={"numero"}
                                    classes={['isRequired']}
                                    id={"numero"}
                                    value={form?.address?.numero}
                                    onchange={setNumero}
                                />
                            </div>
                            <Button loading={loading}>{btnText}</Button>
                        </>
                    </form>
                </main>
            </ScrollYBoxFormsComponent>
        </>
    );
};

export default FormEmployer;
