import React, {FC, useCallback, useEffect, useState} from 'react';
import HeaderButton from "../HeaderButton";
import {HiOutlineArrowCircleLeft} from "react-icons/hi";
import Button from "../Forms/Button";
import axiosClient from "../../services/axios";
import {IOptions} from "../../views/dashboard/configuracao/certs/Add";
import {IForm} from "../../views/dashboard/vistorias/Add";
import FloatingInputComponent from "../Forms/FloatingInput";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "../../pkg/react-tailwindcss-select";
import {Swiper, SwiperSlide} from "swiper/react";
import {Scrollbar} from "swiper";
import ScrollYBoxFormsComponent from "../ScrollYBoxForms";
import { Tooltip as ReactTooltip } from "react-tooltip";
import uuid from 'react-uuid';

interface Props {
    form: IForm,
    setTitle: any,
    setDate: any,
    onSubmit: any,
    loading: any,
    btnText: string,
    selectCompany: IOptions,
    selectEmployer: IOptions,
    selectSurveyor: IOptions,
    selectForm: IOptions,
    setSelectCompany: any,
    setSelectEmployer: any,
    setSelectSurveyor: any,
    setSelectForm: any,
}

interface IData {
    id: string,
    name: string,
    title: string,
}

const FormSurvey: FC<Props> = ({
                                   form,
                                   setTitle,
                                   setDate,
                                   selectCompany,
                                   selectEmployer,
                                   selectSurveyor,
                                   selectForm,
                                   setSelectCompany,
                                   setSelectEmployer,
                                   setSelectSurveyor,
                                   setSelectForm,
                                   loading,
                                   btnText,
                                   onSubmit
                               }) => {
    const [companies, setCompanies] = useState<any>([{
        label: "",
        value: "",
        name: ""
    }]);
    const [employers, setEmployers] = useState<any>([{
        label: "",
        value: "",
        name: ""
    }]);
    const [surveyors, setSurveyors] = useState<any>([{
        label: "",
        value: "",
        name: ""
    }]);
    const [forms, setForms] = useState<any>([{
        label: "",
        value: "",
        name: ""
    }]);
    useEffect(() => {
        axiosClient.get(`/empresas/list-all`)
            .then(({data: {data: dt}}) => {
                setCompanies(dt.map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name})))
                if (form?.company_id) {
                    const selected = dt
                        .filter(({id}: any) => id == form?.company_id)
                        .map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name}));
                    setSelectCompany(selected[0])
                }
            })
        axiosClient.get(`/funcionarios/list-all`)
            .then(({data: {data: dt}}) => {
                setEmployers(dt.map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name})))
                if (form?.company_id) {
                    const selected = dt
                        .filter(({id}: any) => id == form?.employer_id)
                        .map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name}));
                    setSelectEmployer(selected[0])
                }
            })
        axiosClient.get(`/vistoriadores/list-all`)
            .then(({data: {data}}) => {
                setSurveyors(data.map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name})))
                if (form?.surveyor_id) {
                    const selected = data
                        .filter(({id}: any) => id == form?.surveyor_id)
                        .map(({name, id}: IData) => ({"label": name.length > 40 ? name.substring(0, 40) + "..." : name, "value": id, name}));
                    setSelectCompany(selected[0])
                }
            })
        axiosClient.get(`/formularios/list-all`)
            .then(({data: {data}}) => {
                setForms(data.map(({title, id}: IData) => ({"label": title.length > 40 ? title.substring(0, 40) + "..." : title, "value": id, name:title})))
                if (form?.form_id) {
                    const selected = data
                        .filter(({id}: any) => id == form?.form_id)
                        .map(({title, id}: IData) => ({"label": title.length > 40 ? title.substring(0, 40) + "..." : title, "value": id, name: title}))
                    setSelectCompany(selected[0])
                }
            })
    }, []);

    const [dtpicker, setDtpicker] = useState<any>({
        startDate: null,
        endDate: null
    });

    useEffect(() => {
        setDtpicker({
            startDate: formatastringdata(form.date),
            endDate: formatastringdata(form.date)
        })
    }, [form]);


    const formatastringdata = useCallback((data: any) => {
        if (data) {
            const dia = data.split("/")[0];
            const mes = data.split("/")[1];
            const ano = data.split("/")[2];

            return `${ano}-${mes}-${dia}`;
        }
        return data;
    }, [])
    const handleValueChange = (newValue: any) => {
        setDtpicker(newValue);
        setDate(newValue.startDate);
    }

    return (
        <>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={'flex'}>
                        <HeaderButton url={'vistorias/listar'} title={"Voltar"}>
                            <HiOutlineArrowCircleLeft className={'text-6xl text-main-color'}/>
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main className={'max-w-sm sm:max-w-2xl flex items-center justify-center'}>
                    <form action={'#'} method={'POST'} onSubmit={onSubmit} className={'flex flex-col gap-4 w-full shadown rounded-xl'}>
                        <>
                            <FloatingInputComponent type={'text'} label={'Título da Vistoria*'} name={'name'} id={'name'} value={form.title} onchange={setTitle}/>
                            <Datepicker
                                primaryColor={'red'}
                                showShortcuts={false}
                                useRange={false}
                                asSingle={true}
                                value={dtpicker}
                                i18n={"pt"}
                                displayFormat={"DD/MM/YYYY"}
                                placeholder={"Data da vistoria"}
                                inputClassName={'relative w-full transition-all duration-300 rounded-xl pl-6 pr-4 h-16 py-2.5 w-full border-gray-300 ' +
                                    'tracking-wide bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20' +
                                    'placeholder:text-md placeholder:text-gray-500'
                                }
                                onChange={handleValueChange}
                            />
                            <div className={'text-start flex items-center gap-6 md:gap-4'}>
                                <Select primaryColor={"red"}
                                        isSearchable
                                        placeholder={'Empresa'}
                                        noOptionsMessage={"Nenhum resultado encontrado."}
                                        value={selectCompany}
                                        onChange={setSelectCompany}
                                        formatOptionLabel={data => {
                                            const entity = companies.find(({value}) => value == data.value);
                                            return (
                                            <li data-tooltip-id={data.value} className='block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500 hover:bg-red-100 hover:text-red-500'>
                                                {data.label}
                                                {
                                                    <ReactTooltip
                                                        key={String(uuid())}
                                                        id={entity?.value}
                                                        place="top"
                                                        content={entity?.name}
                                                    />
                                                }
                                            </li>
                                        )}}
                                        loading={!companies}
                                        options={companies ? companies : []}/>

                                <span>ou</span>

                                <Select primaryColor={"red"}
                                        isSearchable
                                        placeholder={'Funcionário'}
                                        noOptionsMessage={"Nenhum resultado encontrado."}
                                        value={selectEmployer}
                                        onChange={setSelectEmployer}
                                        formatOptionLabel={data => {
                                            const entity = employers.find(({value}) => value == data.value);
                                            return (
                                            <li data-tooltip-id={data.value} className='block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500 hover:bg-red-100 hover:text-red-500'>
                                                {data.label}
                                                {
                                                    <ReactTooltip
                                                        key={String(uuid())}
                                                        id={entity?.value}
                                                        place="top"
                                                        content={entity?.name}
                                                    />
                                                }
                                            </li>
                                        )}}
                                        loading={!employers}
                                        options={employers ? employers : []}/>
                            </div>

                            <div className={'text-start flex flex-wrap gap-6 md:gap-4'}>
                                <Select primaryColor={"red"}
                                        isSearchable
                                        placeholder={'Vistoriador'}
                                        noOptionsMessage={"Nenhum resultado encontrado."}
                                        value={selectSurveyor}
                                        onChange={setSelectSurveyor}
                                        formatOptionLabel={data => {
                                            const entity = surveyors.find(({value}) => value == data.value);
                                            return (
                                            <li data-tooltip-id={data.value} className='block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500 hover:bg-red-100 hover:text-red-500'>
                                                {data.label}
                                                {
                                                    <ReactTooltip
                                                        key={String(uuid())}
                                                        id={entity?.value}
                                                        place="top"
                                                        content={entity?.name}
                                                    />
                                                }
                                            </li>
                                        )}}
                                        loading={!surveyors}
                                        options={surveyors ? surveyors : []}/>

                                <Select primaryColor={"red"}
                                        isSearchable
                                        placeholder={'Formulário'}
                                        noOptionsMessage={"Nenhum resultado encontrado."}
                                        value={selectForm}
                                        onChange={setSelectForm}
                                        formatOptionLabel={data => {
                                            const entity = forms.find(({value}) => value == data.value);
                                            return (
                                            <li data-tooltip-id={data.value} className='block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500 hover:bg-red-100 hover:text-red-500'>
                                                {data.label}
                                                {
                                                    <ReactTooltip
                                                        key={String(uuid())}
                                                        id={entity?.value}
                                                        place="top"
                                                        content={entity?.name}
                                                    />
                                                }
                                            </li>
                                        )}}
                                        loading={!forms}
                                        options={forms ? forms : []}/>

                            </div>
                            <Button loading={loading}>{btnText}</Button>
                        </>
                    </form>
                </main>
            </ScrollYBoxFormsComponent>
        </>
    );
};

export default FormSurvey;
