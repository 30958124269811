import React, {SyntheticEvent, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import axiosClient from "../../../services/axios";
import axios from "axios";
import {GenerateMask} from "../Home";
import FormCompany from "../../../components/Company/Form";
import {useParams} from "react-router-dom";
import IMask from "imask";
import isFilled from "../../../services/checkRequired";

export interface IForm {
    id?: string,
    name?: string,
    email?: string,
    cnpj?: string,
    unit?: string,
    grau_risco?: string,
    cnae?: string,
    description_cnae?: string,
    address?: IAdress
}

interface IAdress {
    cep?: string,
    tipo_logradouro?: string,
    logradouro?: string,
    bairro?: string,
    cidade?: string,
    estado?: string,
    numero?: number
}

const EditEmpresaComponent = () => {
    const [form, setForm] = useState<IForm>({
        id: "",
        name: "",
        email: "",
        cnpj: "",
        unit: "",
        grau_risco: "",
        cnae: "",
        description_cnae: "",
        address: {
            cep: "",
            tipo_logradouro: "",
            logradouro: "",
            bairro: "",
            cidade: "",
            estado: "",
            numero: 0
        }
    });
    const [loading, setLoading] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        GenerateMask("cep", "00000-000")
        GenerateMask("cnpj", "00.000.000/0000-00")

        axiosClient.get(`/empresas/edit/${id}`)
            .then(({data:{data}}) => {
                setForm(data);
            }).catch(() => {
        })
    }, []);

    const setName = (name: string) => setForm({...form, name});
    const setEmail = (email: string) => setForm({...form, email});
    const setCnpj = (value: string) =>
    {
        const masked = IMask.createMask({mask: '00.000.000/0000-00'});
        value = masked.resolve(value);
        setForm({...form, cnpj:value});
    }
    const setUnit = (value: string) => setForm({...form, unit:value});
    const setGrauRisco = (grau_risco: string) => setForm({...form, grau_risco});
    const setCnae = (value: string) =>
    {
        const masked = IMask.createMask({mask: '00.00-0-00'});
        value = masked.resolve(value);
        setForm({...form, cnae:value});
    }
    const setDescriptionCnae = (value: string) => setForm({...form, description_cnae:value});
    const setCep = (cep: string) => {
        if(cep.length === 9)
        {
            axios.get("https://viacep.com.br/ws/" + cep.replace(/[^0-9]/, "") + "/json/")
                .then( ({data}:any) => {
                    setForm({
                        ...form,
                        address: {
                            ...form.address,
                            cep: data.cep,
                            logradouro: data.logradouro,
                            bairro: data.bairro,
                            cidade: data.localidade,
                            estado: data.uf
                        }
                    });
                })
                .catch(() => {
                    toast.error("CEP inválido.");
                })
        }
        const masked = IMask.createMask({mask: '00000-000'});
        cep = masked.resolve(cep);
        setForm({...form, address: {...form.address, cep:cep}});
    }
    const setTipoLogradouro = (tipo_logradouro: string) => setForm({...form, address: {...form.address, tipo_logradouro}});
    const setLogradouro = (logradouro: string) => setForm({...form, address: {...form.address, logradouro}});
    const setBairro = (bairro: string) => setForm({...form, address: {...form.address, bairro}});
    const setCidade = (cidade: string) => setForm({...form, address: {...form.address, cidade}});
    const setEstado = (estado: string) => setForm({...form, address: {...form.address, estado}});
    const setNumero = (numero: number) => setForm({...form, address: {...form.address, numero}});

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);
        if(isFilled()){
            axiosClient.post('/empresas/update', {...form, role: 2})
                .then(({data: {message}}) => {
                    toast.success(message);
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
            });
        }else{
            toast.error("Por favor preencher os campos destacados.");
            setLoading(false);
        }
    }

    return <FormCompany form={form} onSubmit={onSubmit} loading={loading} btnText={"Atualizar Empresa"}
                        setBairro={setBairro} setCep={setCep} setCidade={setCidade} setCnpj={setCnpj}
                        setEmail={setEmail} setEstado={setEstado} setLogradouro={setLogradouro} setName={setName}
                        setNumero={setNumero} setTipoLogradouro={setTipoLogradouro} setUnit={setUnit} setGrauRisco={setGrauRisco}
                        setCnae={setCnae} setDescriptionCnae={setDescriptionCnae}/>;
};
export default EditEmpresaComponent;
