import React, { FC, useEffect, useState } from "react";
import HeaderButton from "../HeaderButton";
import {
    HiOutlineArrowCircleLeft,
    HiOutlineCheck,
    HiPlus,
} from "react-icons/hi";
import Button from "../Forms/Button";
import FloatingInputComponent from "../Forms/FloatingInput";
import { IForm } from "../../views/dashboard/vistoriadores/Add";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import ScrollYBoxFormsComponent from "../ScrollYBoxForms";
import CertificationComponent from "./Certifications";
import IMask from "imask";

interface Props {
    form: IForm;
    setForm: any;
    onSubmit: any;
    loading: any;
    btnText: string;
}

interface ICertification {
    type?: string;
    mark?: string;
    model?: string;
    serie_number?: string;
    file_id?: string;
}

const FormSurveyor: FC<Props> = ({
    form,
    setForm,
    loading,
    btnText,
    onSubmit,
}) => {
    const [certification, setCertification] = useState<ICertification>({
        type: "",
        mark: "",
        model: "",
        serie_number: "",
        file_id: "",
    });

    const setName = (name: string) => setForm({ ...form, name });
    const setEmail = (email: string) => setForm({ ...form, email });
    const setCpf = (cpf: string) => setForm({ ...form, cpf });
    const setMte = (mte: string) => setForm({ ...form, mte });
    const setRg = (rg: string) => setForm({ ...form, rg });
    const setCertifications = (certifications: []) =>
        setForm({ ...form, certifications });
    const setPhone = (phone: string) => {
        const masked = IMask.createMask({ mask: "(00) 0000-0000" });
        phone = masked.resolve(phone);
        setForm({ ...form, fixed_phone: phone });
    };
    const setCellPhone = (cell_phone: string) => {
        const masked = IMask.createMask({ mask: "(00) 0 0000-0000" });
        cell_phone = masked.resolve(cell_phone);
        setForm({ ...form, cell_phone });
    };

    return (
        <>
            <header
                className={"max-w-sm sm:max-w-2xl flex justify-center gap-4"}
            >
                <Swiper
                    slidesPerView={1}
                    spaceBetween={16}
                    cubeEffect={{
                        shadow: false,
                        slideShadows: false,
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper flex mb-4"
                >
                    <SwiperSlide className={"flex"}>
                        <HeaderButton
                            url={"vistoriadores/listar"}
                            title={"Voltar"}
                        >
                            <HiOutlineArrowCircleLeft
                                className={"text-6xl text-main-color"}
                            />
                        </HeaderButton>
                    </SwiperSlide>
                </Swiper>
            </header>
            <ScrollYBoxFormsComponent>
                <main
                    className={
                        "max-w-sm sm:max-w-2xl flex items-center justify-center"
                    }
                >
                    <form
                        action={"#"}
                        method={"POST"}
                        onSubmit={onSubmit}
                        className={
                            "flex flex-col gap-4 w-full shadown rounded-xl"
                        }
                    >
                        <>
                            <div
                                className={
                                    "text-start flex flex-wrap gap-6 md:gap-4"
                                }
                            >
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Nome Completo*"}
                                    name={"name"}
                                    classes={['isRequired']}
                                    id={"name"}
                                    value={form.name}
                                    onchange={setName}
                                />
                                <FloatingInputComponent
                                    type={"email"}
                                    label={"E-mail*"}
                                    name={"email"}
                                    classes={['isRequired']}
                                    id={"email"}
                                    value={form.email}
                                    onchange={setEmail}
                                />
                                <FloatingInputComponent
                                    classes={["cpf","isRequired"]}
                                    type={"text"}
                                    label={"CPF*"}
                                    name={"cpf"}
                                    id={"cpf"}
                                    value={form.cpf}
                                    onchange={setCpf}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"RG"}
                                    name={"rg"}
                                    id={"rg"}
                                    value={form.rg}
                                    onchange={setRg}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Registro MTE*"}
                                    name={"mte"}
                                    classes={['isRequired']}
                                    id={"mte"}
                                    value={form.mte}
                                    onchange={setMte}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Telefone"}
                                    name={"phone"}
                                    id={"phone"}
                                    value={form.fixed_phone}
                                    onchange={setPhone}
                                />
                                <FloatingInputComponent
                                    type={"text"}
                                    label={"Celular*"}
                                    name={"cell_phone"}
                                    classes={['isRequired']}
                                    id={"cellPhone"}
                                    value={form.cell_phone}
                                    onchange={setCellPhone}
                                />
                                <CertificationComponent
                                    form={form}
                                    certification={certification}
                                    setCertification={setCertification}
                                    setCertifications={setCertifications}
                                />
                            </div>
                            <Button loading={loading}>
                                {btnText} <br />
                            </Button>
                        </>
                    </form>
                </main>
            </ScrollYBoxFormsComponent>
        </>
    );
};

export default FormSurveyor;
