import React, {SyntheticEvent, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import axiosClient from "../../../services/axios";
import {GenerateMask} from "../Home";
import FormSurvey from "../../../components/Survey/Form";
import {IOptions} from "../configuracao/certs/Add";
import {useParams} from "react-router-dom";
import { log } from "node:console";

export interface IForm {
    id?: string,
    title?: string,
    date?: string,
    company_id?: string,
    employer_id?: string,
    surveyor_id?: string,
    form_id?: string
}

const EditVistoriaComponent = () => {
    const [data, setData] = useState<IForm>({
        id: "",
        title: "",
        date: "",
        company_id: "",
        employer_id: "",
        surveyor_id: "",
        form_id: ""
    });
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<IOptions|any>(null);
    const [selectedEmployer, setSelectedEmployer] = useState<IOptions|any>(null);
    const [selectedSurveyor, setSelectedSurveyor] = useState<IOptions|any>(null);
    const [selectedForm, setSelectedForm] = useState<IOptions|any>(null);

    const {id} = useParams();

    useEffect(() => {
        GenerateMask("cpf", "000.000.000-00")
        axiosClient.get(`/vistorias/edit/${id}`)
            .then(({data:{data:dt}}) => {
                setData({...data, id: dt?.id, date: dt?.date, title: dt?.title, company_id: dt?.company_id, employer_id: dt?.employer?.value, surveyor_id: dt?.surveyor_id, form_id: dt.form_id})
                setSelectedCompany(dt?.company)
                setSelectedEmployer(dt?.employer)
                console.log(data);
                
                setSelectedSurveyor(dt?.surveyor)
                setSelectedForm(dt?.form)
            }).catch(() => {
        })
    }, []);


    const setCompany = React.useCallback((company: string) => setData(prevData => ({...prevData, company_id: company})), []);
    const setEmployer = React.useCallback((employer: string) => setData(prevData => ({...prevData, employer_id: employer})), []);
    const setSurveyor = React.useCallback((surveyor: string) => setData(prevData => ({...prevData, surveyor_id: surveyor})), []);
    const setForm = React.useCallback((form: string) => setData(prevData => ({...prevData, form_id: form})), []);
    const seTitle = React.useCallback((title: string) => setData(prevData => ({...prevData, title})), []);
    const seDate = React.useCallback((date: string) => setData(prevData => ({...prevData, date})), []);

    useEffect(() => {
        setCompany(selectedCompany?.value);
    }, [selectedCompany, setCompany]);

    useEffect(() => {
        if(!selectedEmployer?.value){
            return
        }
        setEmployer(selectedEmployer?.value);
    }, [selectedEmployer, setEmployer]);

    useEffect(() => {
        setSurveyor(selectedSurveyor?.value);
    }, [selectedSurveyor, setSurveyor]);

    useEffect(() => {
        setForm(selectedForm?.value);
    }, [selectedForm, setForm]);

    const onSubmit = (event: SyntheticEvent) => {
        event.preventDefault()
        setLoading(true);

        console.log(data?.employer_id);
        if(
            data?.id &&
            data?.title &&
            (data?.company_id ||
            data?.employer_id) &&
            data?.surveyor_id &&
            data?.form_id
        ){
            axiosClient.post('/vistorias/update', data)
                .then(({data: {message}}) => {
                    toast.success(message);
                })
                .catch(() => {
                }).finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
            });
        }else{
            toast.error("Por favor preencher ambos os campos.");
            setLoading(false);
        }
    }

    return <FormSurvey
        form={data}
        setTitle={seTitle}
        setDate={seDate}
        selectForm={selectedForm}
        selectCompany={selectedCompany}
        selectEmployer={selectedEmployer}
        selectSurveyor={selectedSurveyor}
        setSelectForm={setSelectedForm}
        setSelectCompany={setSelectedCompany}
        setSelectEmployer={setSelectedEmployer}
        setSelectSurveyor={setSelectedSurveyor}
        loading={loading}
        onSubmit={onSubmit}
        btnText={"Atualizar Avaliação"}/>;
};

export default EditVistoriaComponent;
